import { useState } from 'react';
import styled, { css } from 'styled-components';
import ApplyOpacity from './ApplyOpacity';
import LogoOpsaci from '../assets/LogoOpsaci.png';

const Header = () => {
  const [isMenuActive, setMenuActive] = useState(false);

  return(<>
    <ApplyOpacity
      onClick={() => setMenuActive(false)}
      isMenuActive={isMenuActive}/>
    <StyledHeader>
      <ButtonLinkContainer>
        <MenuButton onClick={() => setMenuActive(!isMenuActive)}>
          <ButtonLine $line1 $isMenuActive={isMenuActive}/>
          <ButtonLine $line2 $isMenuActive={isMenuActive}/>
          <ButtonLine $line3 $isMenuActive={isMenuActive}/>
        </MenuButton>
        <LinkContainer>
          <LinkHeader>Acerca de Nosotros</LinkHeader>
          <LinkHeader>Productos y Servicios</LinkHeader>
          <LinkHeader>Sucursales</LinkHeader>
        </LinkContainer>
      </ButtonLinkContainer>
      <LogoContainer href='/'>
        <Logo src={LogoOpsaci}/>
      </LogoContainer>
    </StyledHeader>
    <StyledNav $isMenuActive={isMenuActive}>
      <Link>Inicio </Link>
      <Link>Acerca de Nosotros</Link>
      <Link>Productos y Servicios</Link>
      <Link>Blog o Noticias</Link>
      <Link>Sucursales</Link>
    </StyledNav>
  </>);
};

export default Header;

const StyledHeader = styled.header`
  align-items: center;
  display: flex;
  height: 80px;
  justify-content: space-between;
  padding: 0 50px;
  position: absolute;
  width: 100%;
`;

const StyledNav = styled.nav`
  align-items: flex-start;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: 100vh;
  justify-content: center;
  left: ${({ $isMenuActive }) => $isMenuActive ? '0' : '-500px'};
  max-width: 500px;
  padding-left: 50px;
  position: fixed;
  top: 0;
  transition: left .3s;
  width: 100%;
  z-index: 40;
`;

const MenuButton = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 20px;
  justify-content: space-between;
  justify-self: start;
  position: relative;
  width: 30px;
  z-index: 45;
`;

const ButtonLine = styled.span`
  background-color: #666666;
  border-radius: 10px;
  display: block;
  height: 2px;
  width: 100%;

  ${({ $line1 }) => $line1 && css`
    transform: rotate(${({ $isMenuActive }) => $isMenuActive ? '38deg' : '0'});
    transform-origin: 0% 0%;
    transition: transform .4s ease-in-out;
  `};

  ${({ $line2 }) => $line2 && css`
    transform: scaleY(${({ $isMenuActive }) => $isMenuActive ? '0' : '100%'});
    transition: transform .2s ease-in-out;
  `};

  ${({ $line3 }) => $line3 && css`
    transform: rotate(${({ $isMenuActive }) => $isMenuActive ? '-38deg' : '0'});
    transform-origin: 0% 100%;
    transition: transform .4s ease-in-out;
  `};
`;

const ButtonLinkContainer = styled.div`
  display: flex;
  gap: 50px;
`;

const LinkContainer = styled.div`
  display: flex;
  gap: 30px;

  @media (max-width: 700px) {
    display: none;
  };
`;

const LinkHeader = styled.a`
  color:#0077B6;
  cursor: pointer;
  font-size: 1em;
  font-weight: 800;
  transition: transform .3s;

  &:hover {
    transform: scale(105%);
  };
`;

const Link = styled.a`
  color:#000;
  cursor: pointer;
  font-size: 1em;
  font-weight: 800;
  padding: 15px 30px;
  position: relative;

  &::before {  
    transform: scaleX(0);
    transform-origin: bottom right;
  };

  &:hover::before {
    transform: scaleX(1);
    transform-origin: bottom left;
  };

  &::before {
    content: " ";
    display: block;
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    inset: 0 0 0 0;
    background: #FFD700;
    z-index: -1;
    transition: transform .3s ease;
    border-radius: 30px;
  };
`;

const LogoContainer = styled.a`
  width: 60px;
`;

const Logo = styled.img`
  object-fit: cover;
  width: 100%;
`;