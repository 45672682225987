import styled from 'styled-components';
import Animation from './Animation';

const FrontPage = () => {
  return(
    <StyledSection>
      <Titulos>
        <StyledH1>Bienvenido a <span>Cooperativa Opsaci</span></StyledH1>
        <StyledH2>Inversión, Crédito y Ahorro con valores.</StyledH2>
      </Titulos>
      <ImageContainer>
        <Animation/>
      </ImageContainer>
    </StyledSection>
  );
};

export default FrontPage;

const StyledSection = styled.section`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  height: calc(100vh - 80px);
  justify-content: space-between;
  max-width: 1200px;
  margin-top: 80px;
  width: 100%;

  @media (max-width: 1320px) {
    justify-content: center;
    max-width: 90%;
  };
`;

const Titulos = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

const StyledH1 = styled.h1`
  color: #003366;
  display: flex;
  flex-direction: column;
  font-size: 3em;
  gap: 10px;
  text-align: left;

  span {
    color: #006400;
  };

  @media (max-width: 465px) {
    font-size: 2.6em;
  };

  @media (max-width: 405px) {
    font-size: 2.2em;
  };
`;

const StyledH2 = styled.h2`
  color: #666666;
  font-size: 1.5em;

  @media (max-width: 465px) {
    font-size: 1.3em;
  };

  @media (max-width: 405px) {
    font-size: 1em;
  };
`;

const ImageContainer = styled.div`
  min-width: 300px;
  width: 45%;
`;